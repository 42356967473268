import ShopPage from "../component/shop/Shop";
import DealOfTheDay from "../component/hompage/DealOfTheDay";
import ProductShowcase from "../component/hompage/ProductShowcase";



const Shop = () => {
  return (
    <>
    <ShopPage />
    <ProductShowcase />
    </>
  )
}

export default Shop